import { DocumentData, firebaseFirestore, Timestamp } from "../init";

export type Flag = {
  pseudo?: string;
  email?: string;
  flagId: string;
  userId: string;
  reporterId: string;
  reasons: string[];
  conversationId?: string;
  ignored?: boolean;
  timestamp: Date;
};

export type FlagStats = {
  pseudo?: string;
  email?: string;
  userId: string;
  numFlags: number;
  numToProcess: number;
  types: Record<string, number>;
  lastFlagTimestamp: Date;
};

export async function getFlagStatsToProcess(): Promise<FlagStats[]> {
  const flagStats = firebaseFirestore
    .collection("flagStats")
    .where("numToProcess", ">", 0)
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => convertFlagStats(doc.data()) as FlagStats)
    );

  return (await flagStats).sort(
    (a, b) => b.lastFlagTimestamp.getTime() - a.lastFlagTimestamp.getTime()
  );
}

export async function getUserFlags(userId?: string): Promise<Flag[]> {
  if (!userId) return [];
  return firebaseFirestore
    .collection("flags")
    .where("userId", "==", userId)
    .get()
    .then((snapshot) =>
      snapshot.docs.map((doc) => convertFlag(doc.id, doc.data()) as Flag)
    );
}

function convertFlag(
  flagId: string,
  retrieved?: DocumentData
): Flag | undefined {
  if (retrieved === undefined) {
    return undefined;
  }
  const timestamp = retrieved.timestamp as Timestamp;
  retrieved.timestamp = timestamp.toDate();
  return { flagId, ...retrieved } as Flag;
}

function convertFlagStats(retrieved?: DocumentData): FlagStats | undefined {
  if (retrieved === undefined) {
    return undefined;
  }
  const timestamp = retrieved.lastFlagTimestamp as Timestamp;
  retrieved.lastFlagTimestamp = timestamp.toDate();
  return retrieved as FlagStats;
}
