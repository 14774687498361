import { apiRoot } from "../init";

export async function postAdmin<Response>(
  activationKey: string,
  url: string,
  body?: Record<string, unknown>
): Promise<Response> {
  return fetch(`${apiRoot}/${url}`, {
    method: "POST",
    headers: {
      Authorization: activationKey,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: body ? JSON.stringify(body) : undefined,
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<Response>;
    } else {
      const { status, statusText } = response;
      const e = new Error(JSON.stringify({ status, statusText }));
      e.name = "HimoonError";
      throw e;
    }
  });
}
