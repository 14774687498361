import { User } from "../types";

export const UserFields: React.FC<{
  user: User;
}> = ({ user }) => {
  const userFields = Object.entries(user).sort(([key1], [key2]) =>
    key1 === key2 ? 0 : key1 < key2 ? -1 : 1
  ) as [string, unknown][];

  return (
    <div>
      {userFields.map(([field, value]) => (
        <div className="mb-2 p-2 overflow-x-auto bg-gray-100">
          <p className="italic text-xs text-gray-400">{field}</p>
          <p>{JSON.stringify(value, undefined, " ")}</p>
        </div>
      ))}
    </div>
  );
};
