import { postAdmin } from "../admin/api-gateway";

export type Verification = {
  userId: string;
  pseudo: string;
  verificationCode: string;
  verificationStatus?: "pending" | "rejected" | "verified";
  verificationStatusDate: Date;
  profilePictureUrl: string;
  verificationImageUrl: string;
  galleryImages: string[];
};

export async function getPendingVerifications(
  activationKey: string
): Promise<Verification[]> {
  const { verifications } = await postAdmin<{ verifications: Verification[] }>(
    activationKey,
    "v2/admin/pending-verifications"
  );
  return verifications;
}

export async function updateVerificationStatus(
  activationKey: string,
  userId: string,
  newStatus: Verification["verificationStatus"]
) {
  const { verificationStatus } = await postAdmin<{
    verificationStatus: Verification["verificationStatus"];
  }>(activationKey, "v2/admin/set-verification-status", {
    userId,
    verificationStatus: newStatus,
  });
  return verificationStatus;
}
