import { useContext, useState } from "react";
import { LoginContext } from "../contexts/login-context";
import { getUserMessages } from "../helpers/admin/user";

export const LatestMessages: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const { activationKey } = useContext(LoginContext);

  const [messages, setMessages] = useState<{ message: string; date: Date }[]>();

  const fetchMessages = async (upTo?: Date) => {
    if (!activationKey || !userId) return;
    try {
      const fetchedMessages = await getUserMessages(
        activationKey,
        userId,
        upTo
      );
      setMessages(fetchedMessages);
    } catch (err) {
      console.error(err);
    }
  };

  async function fetchMoreMessages() {
    return fetchMessages(
      messages ? messages[messages.length - 1].date : undefined
    );
  }

  async function fetchLastMessages() {
    return fetchMessages();
  }
  return (
    <>
      <div className="flex flex-row mb-2">
        {messages ? (
          <button className="button" onClick={fetchMoreMessages}>
            Fetch More messages
          </button>
        ) : null}
        <button className="button" onClick={fetchLastMessages}>
          Fetch Last messages
        </button>
      </div>

      <div>
        {messages?.map(({ message, date }) => (
          <div className="mb-2 p-2 bg-gray-100">
            <p className="italic text-xs text-gray-400">{date.toISOString()}</p>
            <p className="font-normal">{message}</p>
          </div>
        ))}
      </div>
    </>
  );
};
