import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { LoginContext } from "../contexts/login-context";
import {
  getPendingVerifications,
  Verification,
} from "../helpers/firestore/verification";

export function useVerifications(): [Verification[], () => void] {
  const { activationKey } = useContext(LoginContext);

  const { data: verifications } = useQuery("verifications", () =>
    activationKey ? getPendingVerifications(activationKey) : []
  );

  const queryClient = useQueryClient();
  function invalidate() {
    queryClient.invalidateQueries("verifications");
  }
  return [verifications || [], invalidate];
}
