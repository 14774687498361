import { RouteComponentProps } from "@reach/router";
import { useContext, useState } from "react";
import { LoginContext } from "../contexts/login-context";
import { ignoreFlag } from "../helpers/admin/flags";
import { ADMIN_USER_ID } from "../helpers/admin/user";
import { FlagStats } from "../helpers/firestore/flag-stats";
import { useFlagStats, useUserFlags } from "../hooks/flag-hooks";
import { UserDisplay } from "./user";

type ReportProps = {};

export const Reports: React.FC<RouteComponentProps & ReportProps> = () => {
  const [userId, setUserId] = useState<string>();
  const [flagStats, invalidateFlagStats] = useFlagStats();

  function handleSelectUser(userId?: string) {
    setUserId(userId);
  }

  function forceRefresh() {
    invalidateFlagStats();
  }

  return (
    <div className="p-3">
      {userId !== undefined ? (
        <FlagsDisplay userId={userId} onClose={handleSelectUser} />
      ) : (
        <>
          <button
            className="text-blue-400 hover:text-blue-600 underline"
            onClick={forceRefresh}
          >
            Refresh
          </button>

          <FlagStatDisplay
            flagStats={flagStats}
            onSelectUser={handleSelectUser}
          />
        </>
      )}
    </div>
  );
};

const FlagStatDisplay: React.FC<{
  flagStats: FlagStats[];
  onSelectUser: (userId: string) => unknown;
}> = ({ flagStats, onSelectUser }) => {
  const handleSelectUser = (userId: string) => () => {
    onSelectUser(userId);
  };
  return (
    <table className="border-collapse w-full">
      <thead>
        <tr>
          <th className="cell-title">Pseudo</th>
          <th className="cell-title">UserId</th>
          <th className="cell-title"># (#toProcess)</th>
          <th className="cell-title">Flags</th>
          <th className="cell-title">Last timestamp</th>
          <th className="cell-title">Actions</th>
        </tr>
      </thead>
      <tbody>
        {flagStats.map((flagStat) => (
          <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="cell-content">
              <span>{flagStat.pseudo || "inconnu"}</span>
            </td>
            <td className="cell-content">
              <span>{flagStat.userId}</span>
            </td>
            <td className="cell-content">
              <span>{`${flagStat.numFlags} (${flagStat.numToProcess})`}</span>
            </td>
            <td className="cell-content">
              <span>
                {Object.entries(flagStat.types)
                  .map(([type, count]) => `${type}:${count}`)
                  .join(", ")}
              </span>
            </td>
            <td className="cell-content">
              <span>{flagStat.lastFlagTimestamp.toISOString()}</span>
            </td>
            <td className="cell-content">
              <button
                className="text-blue-400 hover:text-blue-600 underline"
                onClick={handleSelectUser(flagStat.userId)}
              >
                Details
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const FlagsDisplay: React.FC<{ userId: string; onClose: () => unknown }> = ({
  userId,
  onClose,
}) => {
  const [userFlags, invalidateUserFlags] = useUserFlags(userId);
  const { activationKey } = useContext(LoginContext);

  if (!activationKey) return <div>Missing activation key</div>;

  const handleClose = () => {
    onClose();
  };

  const handleIgnore = (flagId: string) => async () => {
    await ignoreFlag(activationKey, flagId);
    invalidateUserFlags();
  };

  return (
    <div>
      <div className="py-3 flex flex-row justify-between">
        <h1 className="py-2 text-lg">
          Flags for <span className="font-bold">{userId}</span>
        </h1>
        <button
          className=" bg-blue-700 text-white border border-blue-700 font-bold py-2 px-6 rounded-lg"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
      <table className="border-collapse w-full text-sm">
        <thead>
          <tr>
            <th className="cell-title">Last timestamps</th>
            <th className="cell-title">reporterId</th>
            <th className="cell-title">reasons</th>
            <th className="cell-title">source</th>
            <th className="cell-title">Actions</th>
          </tr>
        </thead>
        <tbody>
          {userFlags.map((flag) => (
            <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
              <td className="cell-content">
                {/* timestamps */}
                <span>{`${flag.timestamp.toISOString()}`}</span>
              </td>
              <td className="cell-content">
                {/* reporterId */}
                <span>{flag.reporterId}</span>
              </td>
              <td className="cell-content">
                {/* reasons */}
                <span>{flag.reasons.join(", ")}</span>
              </td>
              <td className="cell-content">
                {/* source */}
                <span>
                  {flag.conversationId
                    ? `conversation: ${flag.conversationId}`
                    : flag.reporterId === ADMIN_USER_ID
                    ? "admin"
                    : "swipe"}
                </span>
              </td>
              <td className="cell-content">
                {/* Actions */}
                {flag.ignored ? (
                  <span>Ignored</span>
                ) : (
                  <button
                    className="text-blue-400 hover:text-blue-600 underline"
                    onClick={handleIgnore(flag.flagId)}
                  >
                    Ignore
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {userId && <UserDisplay userId={userId} />}
    </div>
  );
};
