import { RouteComponentProps } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../contexts/login-context";
import { firebaseAuth } from "../helpers/init";

type LoginProps = {
  onActivationKey: (activationKey?: string) => void;
};
export const Login: React.FC<RouteComponentProps & LoginProps> = ({
  onActivationKey,
}) => {
  const { uid, activationKey } = useContext(LoginContext);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [newActivationKey, setNewActivationKey] = useState<string>();
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffect(() => {
    if (uid && !activationKey) {
      firebaseAuth.signOut();
    }
  }, [uid, activationKey]);

  const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "activationKey":
        setNewActivationKey(value);
        break;
      default:
        console.error("unknown field");
    }
  };

  const handleLogin = async () => {
    if (!email || !password || !newActivationKey) {
      setInvalidCredentials(true);
    } else {
      try {
        onActivationKey(newActivationKey);
        await firebaseAuth.signInWithEmailAndPassword(email, password);
        setInvalidCredentials(false);
        setEmail("");
        setPassword("");
        setNewActivationKey("");
      } catch (error) {
        console.error(error);
        setInvalidCredentials(true);
      }
    }
  };

  const handleLogout = () => {
    firebaseAuth.signOut();
  };

  if (uid) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
          <h1 className="font-bold text-center text-2xl mb-5">Himoon admin</h1>
          <div className="bg-white shadow w-full rounded-lg ">
            <div className="px-5 py-7">
              {activationKey ? (
                <p>has activation key</p>
              ) : (
                <p className="text-red-600">No activation key entered</p>
              )}
              <button
                onClick={handleLogout}
                type="button"
                className={
                  "transition duration-200 bg-blue-500 hover:bg-blue-600 " +
                  "focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 " +
                  "text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                }
              >
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <h1 className="font-bold text-center text-2xl mb-5">Himoon admin</h1>
        <div className="bg-white shadow w-full rounded-lg ">
          <div className="px-5 py-7">
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              E-mail
            </label>
            <input
              type="text"
              name="email"
              value={email || ""}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              onChange={handleChangeField}
            />
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={password || ""}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              onChange={handleChangeField}
            />
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Activation key
            </label>
            <input
              type="password"
              name="activationKey"
              value={newActivationKey}
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              onChange={handleChangeField}
            />
            {invalidCredentials && (
              <p className="text-red-600 text-center m-2">
                Invalid credentials
              </p>
            )}
            <button
              onClick={handleLogin}
              type="button"
              className={
                "transition duration-200 bg-blue-500 hover:bg-blue-600 " +
                "focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 " +
                "text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
              }
            >
              <span>Login</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
