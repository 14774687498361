import { useQuery, useQueryClient } from "react-query";
import {
  Flag,
  FlagStats,
  getFlagStatsToProcess,
  getUserFlags,
} from "../helpers/firestore/flag-stats";

export function useFlagStats(): [FlagStats[], () => void] {
  const { data: flagStats } = useQuery("flagStats", () =>
    getFlagStatsToProcess()
  );
  const queryClient = useQueryClient();
  function invalidate() {
    queryClient.invalidateQueries("flagStats");
  }
  return [flagStats || [], invalidate];
}

export function useUserFlags(userId?: string): [Flag[], () => void] {
  const { data: flags } = useQuery(["flags", userId], () =>
    getUserFlags(userId)
  );
  const queryClient = useQueryClient();
  function invalidate() {
    queryClient.invalidateQueries(["flags", userId]);
    queryClient.invalidateQueries("flagStats");
  }
  return [flags || [], invalidate];
}
