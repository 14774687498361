import { RouteComponentProps } from "@reach/router";
import { useContext, useState } from "react";
import { LoginContext } from "../contexts/login-context";
import {
  updateVerificationStatus,
  Verification,
} from "../helpers/firestore/verification";
import { useVerifications } from "../hooks/verification-hooks";

type VerificationProps = {};

export const Verifications: React.FC<
  RouteComponentProps & VerificationProps
> = () => {
  const [verifications] = useVerifications();

  return (
    <div className="p-3">
      <VerificationDisplay verifications={verifications} />
    </div>
  );
};

const VerificationDisplay: React.FC<{
  verifications: Verification[];
}> = ({ verifications }) => {
  const { activationKey } = useContext(LoginContext);
  const [processedUserIds, setProcessedUserIds] = useState<string[]>([]);

  const filteredVerifications = verifications.filter(
    ({ userId }) => !processedUserIds?.includes(userId)
  );

  const handleAccept = (userId: string) => () => {
    if (!activationKey) {
      return;
    }
    updateVerificationStatus(activationKey, userId, "verified").then(() => {
      setProcessedUserIds((userIds) => [userId, ...userIds]);
    });
  };

  const handleReject = (userId: string) => () => {
    if (!activationKey) {
      return;
    }
    updateVerificationStatus(activationKey, userId, "rejected").then(() => {
      setProcessedUserIds((userIds) => [userId, ...userIds]);
    });
  };

  return (
    <table className="border-collapse table-auto w-full">
      <thead>
        <tr>
          <th className="cell-title">User</th>
          <th className="cell-title">Pictures</th>
          <th className="cell-title">Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredVerifications.map((verification, index) => (
          <tr
            key={index}
            className="bg-gray-100 lg:bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td className="cell-content">
              <div>{`code: ${verification.verificationCode}`}</div>
              <div>{`${verification.pseudo} (${verification.userId})`}</div>
              <div>{verification.verificationStatusDate}</div>
            </td>
            <td className="cell-content">
              <div className="flex flex-col">
                <div className="flex flex-row justify-around">
                  <img
                    className="w-5/12"
                    alt="profile"
                    src={verification.profilePictureUrl}
                  />
                  <img
                    className="w-5/12"
                    alt="verification"
                    src={verification.verificationImageUrl}
                  />
                </div>
                <div className="flex flex-row flex-wrap">
                  {verification.galleryImages.map((url, index) => {
                    return (
                      <img
                        key={index}
                        className="w-2/12 m-2"
                        alt="gallery"
                        src={url}
                      />
                    );
                  })}
                </div>
              </div>
            </td>
            <td className="cell-content">
              <div className="flex flex-col justify-around items-center">
                <button
                  className="bg-green-700 rounded-2xl text-white p-2 m-2"
                  onClick={handleAccept(verification.userId)}
                >
                  Accept
                </button>
                <button
                  className="bg-red-700 rounded-sm text-white p-2 m-2"
                  onClick={handleReject(verification.userId)}
                >
                  Reject
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
