import React, { useEffect, useState } from "react";
import { Router, Link, useNavigate, RouteComponentProps } from "@reach/router";
import { Login } from "./pages/login";
import { firebaseAuth, initFirebase } from "./helpers/init";
import { LoginContext } from "./contexts/login-context";
import { Reports } from "./pages/reports";
import { useLocation } from "@reach/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { Users } from "./pages/user";
import { Verifications } from "./pages/user-verifications";

export const RoutedApp: React.FC<RouteComponentProps> = () => {
  const [ready, setReady] = useState(false);
  const [uid, setUid] = useState<string>();
  const [activationKey, setActivationKey] = useState<string>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    let cancelled = false;
    initFirebase().then(() => {
      if (!cancelled) {
        setReady(true);
      }
    });
    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    if (!firebaseAuth) {
      return;
    }
    const initialUid = firebaseAuth?.currentUser?.uid;
    setUid(initialUid || "");
    return firebaseAuth.onAuthStateChanged(async (authUser) => {
      if (authUser?.uid !== undefined) {
        setUid(authUser.uid);
      } else {
        setUid("");
      }
    });
  }, [setUid]);

  const isLoginPage = !pathname || pathname === "/";
  useEffect(() => {
    if (isLoginPage) {
      return;
    }
    if (uid && !activationKey) {
      navigate("/");
    }
  }, [activationKey, isLoginPage, navigate, uid]);

  if (!ready) return <div>Initializing...</div>;

  return (
    <LoginContext.Provider value={{ uid, activationKey }}>
      <div>
        <nav>
          <Link to="/">
            <button className="nav-btn">Login</button>
          </Link>
          <Link to="user-verifications">
            <button className="nav-btn">User verifications</button>
          </Link>
          <Link to="users">
            <button className="nav-btn">Users</button>
          </Link>
          <Link to="reports">
            <button className="nav-btn">Reports</button>
          </Link>
        </nav>
        <Router primary={false}>
          <Login path="/" onActivationKey={setActivationKey} />
          <Reports path="reports" />
          <Users path="users/*" />
          <Verifications path="user-verifications/*" />
        </Router>
      </div>
    </LoginContext.Provider>
  );
};

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <RoutedApp path="/*" />
      </Router>
    </QueryClientProvider>
  );
};
