import firebase from "firebase";
import firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

export const apiRoot =
  process.env.NODE_ENV === "development"
    ? "http://192.168.0.35:4000/api"
    : "/api";

type WebConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

type Config = {
  webconfig: WebConfig;
};

export let firebaseAuth: firebase.auth.Auth;
export let firebaseFirestore: firebase.firestore.Firestore;

export async function initFirebase(): Promise<void> {
  if (firebaseAuth) {
    return;
  }
  const response = await fetch(`${apiRoot}/config`);
  if (!response.ok) {
    throw new Error("Could not initialize firebase");
  }
  const { webconfig } = (await response.json()) as Config;
  firebaseApp.initializeApp(webconfig);
  firebaseAuth = firebaseApp.auth();
  firebaseAuth.languageCode = "fr";
  firebaseFirestore = firebaseApp.firestore();
  console.info("Firebase initialized");
}

export const FieldValue = firebaseApp.firestore.FieldValue;
export type Timestamp = firebaseApp.firestore.Timestamp;
export type DocumentData = firebaseApp.firestore.DocumentData;
export type TFirestoreQuery =
  firebase.firestore.Query<firebase.firestore.DocumentData>;
export type TFirebaseSnapshotDoc =
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
