import { User } from "../../types";
import { postAdmin } from "./api-gateway";

export const ADMIN_USER_ID = "PicmswnPiiPWvkUAmjlFLPeJOwx1";

export async function getUser(
  activationKey: string,
  userId: string
): Promise<User> {
  const user = await postAdmin<User>(activationKey, `admin/user/${userId}`, {
    authorization: activationKey,
  });
  return user;
}

export async function getUserMessages(
  activationKey: string,
  userId: string,
  upTo?: Date
): Promise<{ message: string; date: Date }[]> {
  const url = `admin/users/${userId}/messages${
    upTo ? "/" + upTo?.toISOString() : ""
  }`;
  console.debug(url);
  const messages = await postAdmin<Record<string, string>[]>(
    activationKey,
    url,
    {
      authorization: activationKey,
    }
  );
  return messages.map(({ message, date }) => ({
    message,
    date: new Date(date),
  }));
}

export async function blockUser(
  activationKey: string,
  userId: string
): Promise<{ ok: boolean }> {
  await postAdmin(activationKey, `admin/block-user/${userId}`, {
    authorization: activationKey,
  });
  return { ok: true };
}

export async function unblockUser(
  activationKey: string,
  userId: string
): Promise<{ ok: boolean }> {
  await postAdmin(activationKey, `admin/unblock-user/${userId}`, {
    authorization: activationKey,
  });
  return { ok: true };
}
