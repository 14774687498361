import { RouteComponentProps, useNavigate } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { LatestMessages } from "../components/latest-messages";
import { UserFields } from "../components/user-fields";
import { LoginContext } from "../contexts/login-context";
import { blockUser, getUser, unblockUser } from "../helpers/admin/user";
import { User } from "../types";

type UserProps = {
  "*"?: string;
};

export const Users: React.FC<RouteComponentProps & UserProps> = ({
  "*": userId,
}) => <UserDisplay userId={userId} />;

export const UserDisplay: React.FC<{ userId?: string }> = ({ userId }) => {
  const [user, setUser] = useState<User>();
  const [userError, setUserError] = useState<string>();
  const { activationKey } = useContext(LoginContext);
  const navigate = useNavigate();

  const fetchedUserId = user?.userId;
  useEffect(() => {
    let cancelled = false;
    async function fetchUser(): Promise<void> {
      if (!activationKey || !userId) return;
      try {
        const user = await getUser(activationKey, userId);
        if (!cancelled) setUser(user);
      } catch (err) {
        console.error(err);
        setUserError("Error fetching user.");
      }
    }

    if (fetchedUserId && fetchedUserId !== userId) {
      setUser(undefined);
    } else if (userId && !fetchedUserId) {
      fetchUser();
    }
    return () => {
      cancelled = true;
    };
  }, [activationKey, fetchedUserId, userId]);

  if (!activationKey) return <div>Missing activation key</div>;

  const resetUserId = () => {
    navigate("/users");
  };

  function handleSelectUser(userId?: string) {
    navigate(`/users/${userId}`);
  }

  async function handleBlock() {
    if (!userId || !user || !activationKey) return;
    await blockUser(activationKey, userId);
    setUser(await getUser(activationKey, userId));
  }

  async function handleUnBlock() {
    if (!userId || !user || !activationKey) return;
    await unblockUser(activationKey, userId);
    setUser(await getUser(activationKey, userId));
  }

  return (
    <div className="p-3">
      {user === undefined ? (
        <>
          <UserSelector onSelect={handleSelectUser} />

          {userError && (
            <div className="rounded-lg w-1/3 m-auto text-center">
              <p className="text-red-600">{userError}</p>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="bg-white shadow rounded-lg w-1/3 m-auto">
            <button className="button" onClick={resetUserId}>
              Close
            </button>
            {user?.blurredPictureUrl && (
              <div className="p-3">
                <img src={user.profilePictureUrl} alt="" />
              </div>
            )}
            <div className="p-3">
              <p>{`${user.pseudo} (${user.userId})`}</p>
              <p>{`blocked: ${user.isBlocked || false}`}</p>
              <p>{`${user.email}`}</p>
            </div>

            <div className="flex flex-row justify-between py-3">
              <button className="button" onClick={handleBlock}>
                Block
              </button>
              <button className="button" onClick={handleUnBlock}>
                Unblock
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-12">
            <div>
              <p className="text-lg mt-2 mb-2 font-bold">User</p>
              <UserFields user={user} />
            </div>
            <div>
              <p className="text-lg mt-2 mb-2 font-bold">Messages</p>
              <LatestMessages userId={user.userId} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const UserSelector: React.FC<{
  onSelect: (userId: string) => void;
}> = ({ onSelect }) => {
  const [userId, setUserId] = useState<string>();

  const handleUpdateUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value);
  };

  const handleSelect = async () => {
    if (userId) {
      try {
        onSelect(userId);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className="bg-white shadow rounded-lg w-1/3 m-auto">
      <div className="px-5 py-7">
        <label className="font-semibold text-sm text-gray-600 pb-1 block">
          UserId
        </label>
        <input
          type="text"
          name="userId"
          value={userId}
          className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
          onChange={handleUpdateUserId}
        />
        <button onClick={handleSelect} type="button" className="button">
          <span>Select user</span>
        </button>
      </div>
    </div>
  );
};
