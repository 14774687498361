import { postAdmin } from "./api-gateway";

export async function ignoreFlag(
  activationKey: string,
  flagId: string
): Promise<boolean> {
  const { ignored } = await postAdmin<{ ignored: string }>(
    activationKey,
    "admin/ignore-flag",
    { authorization: activationKey, flagId }
  );
  return !!ignored;
}
