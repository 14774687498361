import React from "react";

export type LoginContextState = {
  uid?: string;
  activationKey?: string;
};

export const LoginContext = React.createContext<LoginContextState>({
  uid: undefined,
  activationKey: undefined,
});
